.App {
  text-align: center;
}


.App-header {
  background-color: #5D7F64;
  min-height: 66vh; 
  display: flex;
  flex-direction: column;
  text-align: center;
  
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  line-height: 10px;
}

.App-body{
  background-color: #F5F9F6;
}

.App-link {
  color: #61dafb;
}


.image{
  height: 500px;
  width: 500px;
}

.headerContainer{
  display: flex;
  align-items: center;
}

span{
  padding: 20px;
}

h1{
  padding-bottom: 30px;
}
 
.hi{
  padding-right: 105px;
  font-size: 84px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
